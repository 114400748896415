nav {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    height: 30px;
    z-index: 1;
    justify-content: space-between;
    padding: 20px;
  
    transition-timing-function: ease-in;
    transition: all 0.5s;
  }
  
  .nav__black {
    background-color: #111;
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    height: 30px;
    z-index: 1;
    justify-content: space-between;
    padding: 20px;
  
    transition-timing-function: ease-in;
    transition: all 0.5s;
  }
  
  .nav__logo {
    position: fixed;
    left: 20px;
    width: 100px;
    object-fit: contain;
  }
  
  .nav__avatar {
    position: fixed;
    right: 20px;
    width: 30px;
    object-fit: contain;
  }